<template>
    <div class="specialties">
        <MainHeader
            class="specialties__header"
            title="Специальности"
            :number="specialtiesLength ? specialtiesList.specialities_count : ''"
            btnText="Новая специальность"
            @btnClick="openNewSpecialtyPopup"
        />
        <VDefaultInput
            v-model="search"
            @update:modelValue="searchSpecialties"
            placeholder="Поиск специальности"
            border
            class="specialties__search"
        >
            <template #iconLeft>
                <img src="@/assets/images/icons/search.svg" alt="search"/>
            </template>
            <template #iconRight>
                <div
                    class="specialties__search-clear"
                    v-if="search"
                    @click="clearSearch"
                >
                    <img src="@/assets/images/icons/x.svg" alt="search"/>
                </div>
            </template>
        </VDefaultInput>
        <AlphabeticalFiltering :letters="letters" v-model="filter" class="specialties__filtering"/>

        <div class="specialties__content">
            <LetterBlock
                v-for="(item, key) of specialtiesList.specialities"
                :key="key"
                v-show="item.length && (key === filter || filter === 'all')"
                :letter="key"
                :specialities="item"
                @getSpecialities="getSpecialties"
                class="specialties__letter"
            />
        </div>

        <div class="specialties__no-content" v-if="!specialtiesLength">
            <VNoContent
                v-if="!search"
                title="Список специальностей пуст"
                description="Нет специальностей, подходящих под выбранный фильтр"
            />
            <VNoContent
                v-else
                title="Ничего не найдено"
                description="Проверьте пожалуйста, правильно ли вы написали название"
            />
        </div>

        <ModalWindow v-if="showNewSpecialtyPopup" @close="closeNewSpecialty" class="specialties__new-specialty">
            <NewSpecialty @close="closeNewSpecialty"/>
        </ModalWindow>

        <ModalWindow v-if="showPopupError" @close="showPopupError = false" class="specialties__error-modal">
            <ErrorModal @close="showPopupError = false" title="Ошибка" description="Проверьте подключение к Интернету." />
        </ModalWindow>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from "vue";
import specialties from "@/api/specialties";

import MainHeader from "@/components/MainHeader";
import AlphabeticalFiltering from '@/components/Specialties/AlphabeticalFiltering'
import NewSpecialty from '@/components/ModalWindow/ModalBodyes/NewSpecialty'
import LetterBlock from '@/components/Specialties/LetterBlock'
import ErrorModal from '@/components/ModalWindow/ModalBodyes/ErrorModal'

const showNewSpecialtyPopup = ref(false)
const showPopupError = ref(false)

const letters = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Ц', 'Ч', 'Ш', 'Э', 'Ю', 'Я']
const search = ref('')
const filter = ref('all')

const specialtiesList = ref({})

const specialtiesLength = computed(() => {
    let arr = []

    for (let key in specialtiesList.value.specialities) {
        if (filter.value === 'all') {
            if (specialtiesList.value.specialities[key].length) arr.push(1)
        } else {
            if (specialtiesList.value.specialities[filter.value].length) arr.push(1)
        }
    }

    return !!arr.length
})

const getSpecialties = async (query) => {
    try {
        specialtiesList.value = await specialties.getSpecialties(query)
    } catch (error) {
        console.log(error)
    }
}

const searchSpecialties = () => {
    if (search.value.length < 3) {
        getSpecialties({search: ''})
        return
    }
    filter.value = 'all'
    getSpecialties({search: search.value})
}

const clearSearch = () => {
    search.value = "";
    getSpecialties()
}

const closeNewSpecialty = () => {
    showNewSpecialtyPopup.value = false
    getSpecialties()
}

const openNewSpecialtyPopup = () => {
    if (!navigator.onLine) {
        showPopupError.value = true
        return
    }
    showNewSpecialtyPopup.value = true
}

onMounted(() => getSpecialties())
</script>

<style scoped lang="scss">
.specialties {
    &__header {
        margin-top: 32px;
    }

    &__search {
        margin-top: 24px;
        width: 100%;

        &::v-deep(.v-input__input) {
            &::placeholder {
                color: $dark-blue;
                opacity: 1;
            }
        }
    }

    &__filtering {
        margin-top: 24px;
    }

    &__new-specialty {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }

    &__content {
        margin-top: 24px;
        padding-bottom: 80px;
    }

    &__no-content {
        width: 100%;
        height: calc(100vh - 500px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__letter {
        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    &__error-modal {
        &::v-deep(.modal__body) {
            width: 304px;

            .modal-body__description {
                max-width: 300px;
            }
        }
    }
}
</style>
