<template>
    <div class="filtration">
        <div
            class="filtration__all"
            :class="{'filtration__all_active': inputVal === 'all'}"
            @click="inputVal = 'all'">Все
        </div>
        <div
            class="filtration__letter"
            v-for="(letter, index) in letters"
            :key="index"
            :class="{'filtration__letter_active': inputVal === letter}"
            @click="inputVal = letter">{{ letter }}
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    letters: {
        type: Array,
        required: true
    },
    modelValue: [String, Number],
})

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});
</script>

<style scoped lang="scss">
.filtration {
    width: 100%;
    height: 48px;
    padding: 6px 4px;
    display: flex;
    border-radius: 12px;
    background-color: $light-gray-1;

    &__all {
        height: 100%;
        padding: 0 9.5px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;

        &:hover {
            background-color: $light;
        }

        &_active {
            background-color: #fff;
            color: $dark;
            font-weight: 400;

            &:hover {
                background-color: #fff;
                color: $dark;
            }
        }
    }

    &__letter {
        width: 36px;
        height: 36px;
        margin-left: 3.62px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        color: $gray;

        &:hover {
            background-color: $light;
        }

        &_active {
            background-color: #fff;
            color: $dark;
            font-weight: 400;

            &:hover {
                background-color: #fff;
            }
        }
    }
}
</style>
