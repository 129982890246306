<template>
    <div class="prohibit-delete">
        <div class="prohibit-delete__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>
        <img src="@/assets/images/icons/aler-cricle-red.svg" class="prohibit-delete__alert-cricle" alt="alert">
        <h3 class="prohibit-delete__title">Нельзя удалить</h3>
        <p class="prohibit-delete__description">Данная специальность используется в вакансиях</p>
        <VButton label="Закрыть" clickEffect class="prohibit-delete__btn" @click="$emit('close')"/>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.prohibit-delete {
    position: relative;
    padding: 40px 24px 24px 24px;
    text-align: center;

    &__close {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    &__alert-cricle {
        margin-top: -20px;
    }

    &__title {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 8px;
        font-size: 16px;
    }

    &__btn {
        width: 100%;
        margin-top: 24px;
    }
}
</style>
