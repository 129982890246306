<template>
    <div class="letter">
        <h3 class="letter__letter">{{ letter }}</h3>

        <div class="letter__content">
            <div class="letter__specialties" v-for="(specialitiesCol, index) in sectionsSpecialties" :key="index">
                <SpecialityBlock v-for="speciality in specialitiesCol"
                                 :key="speciality.id"
                                 :speciality="speciality"
                                 @openEditSpecialtyPopup="openEditSpecialtyPopup"
                                 @deleteSpecialty="deleteSpecialty"
                                 @prohibitDelete="showProhibitDeletePopup = true"
                                 class="letter__speciality"/>
            </div>
        </div>

        <ModalWindow
            v-if="showEditSpecialtyPopup"
            @close="closeEditSpecialtyPopup"
            class="letter__modal">
            <NewSpecialty :editId="editId" :nameSpecialty="nameSpecialty" @close="closeEditSpecialtyPopup"/>
        </ModalWindow>

        <ModalWindow
            v-if="showProhibitDeletePopup"
            @close="showProhibitDeletePopup = false"
            class="letter__modal">
            <ProhibitDelete @close="showProhibitDeletePopup = false"/>
        </ModalWindow>

        <ModalWindow v-if="showPopupError" @close="showPopupError = false" class="letter__error-modal">
            <ErrorModal @close="showPopupError = false" title="Ошибка" description="Проверьте подключение к Интернету." />
        </ModalWindow>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from "vue";

import specialties from "@/api/specialties";
import NewSpecialty from '@/components/ModalWindow/ModalBodyes/NewSpecialty'
import ProhibitDelete from '@/components/ModalWindow/ModalBodyes/ProhibitDelete'
import SpecialityBlock from '@/components/Specialties/SpecialityBlock'
import ErrorModal from '@/components/ModalWindow/ModalBodyes/ErrorModal'

const props = defineProps({
    letter: {
        type: String,
        required: true
    },
    specialities: {
        type: Array,
        required: true
    },
})

const showEditSpecialtyPopup = ref(false)
const showProhibitDeletePopup = ref(false)
const showPopupError = ref(false)

const editId = ref(null)
const nameSpecialty = ref('')

const emit = defineEmits(["getSpecialities"]);

const openEditSpecialtyPopup = (speciality) => {
    if (!navigator.onLine) {
        showPopupError.value = true
        return
    }
    showEditSpecialtyPopup.value = true
    editId.value = speciality.id
    nameSpecialty.value = speciality.name
}

const closeEditSpecialtyPopup = () => {
    showEditSpecialtyPopup.value = false
    editId.value = null
    nameSpecialty.value = ''
    emit('getSpecialities')
}

const deleteSpecialty = async (id) => {
    try {
        await specialties.deleteSpecialty(id)
        emit('getSpecialities')
    } catch (error) {
        console.log(error)
    }
}

const sectionsSpecialties = computed(() => {
    let specialitiesRemainder = props.specialities.length % 3
    let specialitiesLength = props.specialities.length / 3

    const section1 = Math.floor(specialitiesRemainder > 0 ? specialitiesLength + 1 : specialitiesLength)
    const section2 = Math.floor(specialitiesRemainder > 1 ? specialitiesLength + 1 : specialitiesLength)
    const section3 = Math.floor(specialitiesLength)

    const sectionsSpecialties = [[], [], []]

    props.specialities.forEach((item, index) => {
        if (index < section1) {
            sectionsSpecialties[0].push(props.specialities[index])
        }
        if (index + section1 < section1 + section2) {
            sectionsSpecialties[1].push(props.specialities[index + section1])
        }
        if (index + section1 + section2 < section1 + section2 + section3) {
            sectionsSpecialties[2].push(props.specialities[index + section1 + section2])
        }
    })

    return sectionsSpecialties
})
</script>

<style scoped lang="scss">
.letter {
    width: 100%;
    padding: 25px 16px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0 #0000000A;

    &__letter {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 500;
    }

    &__content {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    &__specialties {
        width: 33%;
        min-height: 100%;

        &:not(:first-child) {
            padding-left: 37.5px;
            border-left: 1px solid $light-gray-1;
        }
    }

    &__speciality {
        &:not(:first-child) {
            margin-top: 24px;
        }
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }

    &__error-modal {
        &::v-deep(.modal__body) {
            width: 304px;

            .modal-body__description {
                max-width: 300px;
            }
        }
    }
}
</style>
