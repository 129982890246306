<template>
    <div class="new-specialty">
        <div class="new-specialty__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="new-specialty__title" v-if="editId">Редактировать специальность</h3>
        <h3 class="new-specialty__title" v-else>Новая специальность</h3>
        <div class="new-specialty__inp-wrapper">
            <VCustomInput
                placeholder="Название специальности*"
                :error="(validate && !name) || (errorText.length !== 0)"
                v-model="name"
                class="new-specialty__inp"/>
            <p class="new-specialty__inp-error" v-if="validate && !name">Заполните обязательное поле</p>
            <p class="new-specialty__inp-error" v-if="errorText">{{ errorText }}</p>
        </div>

        <div class="new-specialty__btns">
            <VButton
                label="Сохранить"
                :disabled="disabled"
                clickEffect
                v-if="editId"
                @click="submit"
                class="new-specialty__btn"/>
            <VButton
                label="Добавить"
                :disabled="disabled"
                clickEffect
                v-else
                @click="submit"
                class="new-specialty__btn"/>

            <VButton
                label="Отменить"
                bg="#E4E7EE"
                bgHover="#BBC4CD"
                color="#1E3959"
                colorHover="#1E3959"
                @click="$emit('close')"
                class="new-specialty__btn"/>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, watch} from "vue";
import specialties from "@/api/specialties";

const props = defineProps({
    editId: Number,
    nameSpecialty: String
})

const name = ref('')
const validate = ref(false)
const disabled = ref(false)
const errorText = ref('')

const emit = defineEmits(["close"]);

onMounted(() => {
    name.value = props.editId ? props.nameSpecialty : ''
})


watch(() => name.value, () => {
    errorText.value = ''
})

const submit = async () => {
    if (!name.value) {
        validate.value = true
        return
    }

    if (!navigator.onLine) {
        emit('close')
        return
    }

    disabled.value = true

    try {
        if (!props.editId) {
            await specialties.postSpecialty({
                name: name.value
            })
        } else {
            await specialties.putSpecialty(props.editId, {
                name: name.value
            })
        }
        emit("close")
    } catch (error) {
        console.log(error)
        errorText.value = error?.response?.data?.errors?.name[0]
        disabled.value = false
    }
}
</script>

<style scoped lang="scss">
.new-specialty {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
    }

    &__inp-wrapper {
        width: 100%;
    }

    &__inp {
        width: 100%;
        margin-top: 16px;
    }

    &__inp-error {
        margin-top: 4px;
        font-size: 14px;
        color: $red;
    }

    &__btns {
        width: 100%;
    }

    &__btn {
        width: 100%;
        margin-top: 24px;

        &:last-child {
            margin-top: 12px;

            &:active {
                background-color: #8F9CAC !important;
            }
        }
    }
}
</style>
